import { useState, createContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Homepage from './components/Homepage';
import Dashboard from './components/Dashboard';
import BNBRide from './artifacts/contracts/RideGen.sol/RideGen.json';
import Fee from './artifacts/contracts/Fee.sol/Fee.json'
import { message } from 'antd';
import { ethers } from 'ethers';
import Teams from './components/Teams';
import BinaryTree from './components/BinaryTree';
import Admin from './components/Admin';
import Red from './components/Red';
import Downline from './components/Downline'
import Test from './components/Test';

const AppState = createContext();

function App() {
  const {ethereum} = window;
  const navigate = useNavigate();
  const [theme, setTheme] = useState('dark');
  const [change, setChange] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [id, setId] = useState("0");
  const [bal, setBal] = useState(0);
  const [bal2, setBal2] = useState(0);
  const [bal3, setBal3] = useState(0);
  const [shown, setShown] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const ranks = ["Gen-1", "Gen-2", "Gen-3", "Gen-4", "Gen-5", "Gen-6", "Gen-7", "Gen-8", "Gen-9", "Gen-10", "Gen-11", "Gen-12"];
  const contractAddress = "0x41aE8D79C863b4060a903e2f0a3Bf84C7deB850c";
  const feeAddress = "0x9CEAb9643861c65EEf41CA6952928Ba5Af8a7fd1";
  const royaltyAddress = "0xb976cfbf007481a483f282E87B71BEeBb675abD9";
  const [levels, setLevels] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
  const [percent, setPercent] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);

  let provider;
  let signer;

  async function getProvider() {
    if(ethereum == null) {
      provider = new ethers.JsonRpcProvider();
    } else {
      provider = new ethers.BrowserProvider(window.ethereum);
      signer = await provider.getSigner();
      let _bal = await provider.getBalance(walletAddress);
      let _bal2 = await provider.getBalance(feeAddress);
      let _bal3 = await provider.getBalance(royaltyAddress);
      setBal(convert(_bal));
      setBal2(convert(_bal2));
      setBal3(convert(_bal3));
    }
  }
  getProvider();

  async function getContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(contractAddress, BNBRide.abi, signer);
  }

  async function getFeeContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(feeAddress, Fee.abi, signer);
  }
  
  const staticContract = new ethers.Contract(contractAddress, BNBRide.abi, provider);
  const staticFeeContract = new ethers.Contract(feeAddress, Fee.abi, provider);

  useEffect(() => {
    let _theme = localStorage.getItem('theme');
    if(_theme !== null) {
      setTheme(_theme);
    }

    async function getWallet() {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        setWalletAddress(accounts[0]);
      } catch (error) {
        message.error("Install Web3 Wallet");
      }
    }

    getWallet();
  },[])

  useEffect(() => {
    async function getLevels() {
      setLoaded(false);
      let _lvl = await staticContract.getLevels();
      setLevels([0])
      for(let i=0; i<_lvl[0].length; i++) {
        setLevels((prev) => [...prev, Number(ethers.formatEther(_lvl[0][i]))]);
      }
      setPercent([0]);
      for(let i=0; i<_lvl[1].length; i++) {
        setPercent((prev) => [...prev, Number(_lvl[1][i])]);
      }
      let _id = await staticContract.id(walletAddress);
      setId(Number(_id));
      // setId(1725718426);
      // setId(18577);
      setLoaded(true);
    } 
    getLevels();
  },[walletAddress, change])

  const toggle = () => {
    if(theme === 'dark') {
      setTheme('light');
      localStorage.setItem('theme', 'light');
      } else if(theme === 'light') {
        setTheme('dark');
        localStorage.setItem('theme', 'dark');
    }
  }

  const Login = async () => {
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      setWalletAddress(accounts[0]);

      let id = await staticContract.id(accounts[0])
      let zeroAddr = 0;
      if(Number(id) == zeroAddr) {
        message.error("User Not Registered");
      } else {
        message.success("Logged In");
        navigate('/dashboard');
      }
    } catch (error) {
      message.error("Install Web3 Wallet");
    }
  }

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  const convert = (val) => {
    return Number(ethers.formatEther(val));
  }

  function getUTCTime(ms) {
    const now = new Date(ms);

    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1; // Months are zero-indexed
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();

    const utcTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} UTC`;
    return utcTime;
  }

  return (
    <AppState.Provider value={{formatTime,getUTCTime, staticFeeContract, getFeeContract, loaded, shown, setShown, feeAddress, royaltyAddress, bal, bal2, bal3, id, navigate, percent, levels, ranks, toggle, getContract, theme, Login, walletAddress, change, setChange, staticContract, convert, contractAddress}}>
    <body className={theme}>
      <div className="App">
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/team' element={<Teams />} />
          <Route path='/matrix' element={<BinaryTree />} />
          <Route path='/wire' element={<Admin />} />
          <Route path='/adapter' element={<Red />} />
          <Route path='/downline' element={<Downline />} />
          {/* <Route path='/tester' element={<Test />} /> */}
        </Routes>
      </div>
    </body>
    </AppState.Provider>
  );
}

export default App;
export {AppState};
