import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppState } from '../App'
import { message } from 'antd';
import {useTimer} from 'react-timer-hook'
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import moment from 'moment'
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyFilled } from '@ant-design/icons';

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const UserInfo = () => {
    const useAppState = useContext(AppState);
    const intervalRef = useRef();
    const [user, setUser] = useState({
      start: 0,
      level: 0,
      unblocked: true,
      directTeam: 0,
      directRequired: 0,
      directTime: 0,
      referrer: ""
    })
    const [time, setTime] = useState(0);
    const [showTimer, setShowTimer] = useState(false);

    useEffect(() => {
      intervalRef.current = setInterval(() => {
        if(Number(user.start) > 0) {
          setTime(Date.now() - Number(user.start));
        } else {
          setTime(0);
        }
      }, 10);
      return () => clearInterval(intervalRef.current);
    }, [user.start]);

    useEffect(() => {
      async function getData() {
        setShowTimer(false);

        let _user = await useAppState.staticContract.userInfo(useAppState.id);
        setUser({
          start: Number(_user.start) * 1000,
          level: Number(_user.level),
          directTeam: Number(_user.directTeam),
          directRequired: 2,
          referrer: Number(_user.referrer)
        })
        setShowTimer(true);
      }
      getData();
    },[useAppState.walletAddress, useAppState.change, useAppState.id])

  return (
    <div className="mt-4 w-full flex flex-col md:flex-row items-center bg-[#8080821a] px-4 py-8 md:px-6 rounded-2xl shadow-lg red_blur">
      <div className="com h-[80px] md:h-[80px] ml-4 mt-4 mb-8 md:mb-0">
        <a className="th" target="_blank">
          <div className="relative bg-gray-900 bg-opacity-100 font-bold h-full w-full flex justify-center items-center red_blur">
            <img className="absolute top-0 rotate-180" src="pink-blur.png" />
            <span className="-rotate-45">{useAppState.id}</span>
        </div>
        </a>
      </div>
      <div className="ml-0 md:ml-12 mt-4 md:mt-0">
        <p className="mt-2 text-lime-400 font-semibold">Account : <span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl px-3">{useAppState.walletAddress.slice(0,6)}...{useAppState.walletAddress.slice(38)}</span></p>
        <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Member : </span>Since <span className="text-gray-300 bg-gray-500 bg-opacity-20 rounded-2xl px-3">{useAppState.formatTime(time)}</span></div>
        <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Referrer : </span><span className="text-gray-300 bg-gray-500 bg-opacity-20 rounded-2xl px-3">{user.referrer}</span></div>
        <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Rank : </span><span className="text-gray-300 bg-gray-500 bg-opacity-20 rounded-2xl px-3">{useAppState.ranks[user.level - 1]}</span></div>
        <p className="font-semibold break-all mt-2"><span className="text-lime-400 font-medium">Refer Link : </span><span className="bg-gray-500 text-gray-300 bg-opacity-20 rounded-2xl px-2">ridegen.live/register?ref={useAppState.id}</span>
          <CopyToClipboard text={`https://ridegen.live/dashboard?ref=${useAppState.id}`} >
            <CopyFilled onClick={() => message.success("Copied")} fontSize="small" color="primary" className="ml-2 mr-2 cursor-pointer" />
          </CopyToClipboard>
        </p>
      </div>
    </div>
  )
}

export default UserInfo