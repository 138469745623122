import { useContext } from 'react'
import { AppState } from '../App'
import { Carousel, message } from 'antd';
import Marquee from 'react-fast-marquee';

const Homepage = () => {
  const useAppState = useContext(AppState);

  return (
    <div className="relative h-screen overflow-x-hidden">
      <header className="flex bg-[#18191a] z-40 shadow-lg p-5 justify-between items-center w-full h-[70px] sticky top-0">
        <h1 className="text-3xl font-bold">
           <img
              src="logo.png"
              className="h-12 mr-3"
              alt="ridegen"
            />
        </h1>
        <div className="flex justify-center items-center">
          <p
            onClick={() => useAppState.Login()}
            className="bg-lime-500 hover:bg-lime-600 py-2 px-6 cursor-pointer font-semibold text-sm rounded-md"
          >
            Login
          </p>
        </div>
      </header>

      <div className="relative flex justify-center h-screen items-center">
        <img
          src="background.png"
          className="absolute top-0 hidden md:block h-full w-full"
        />
        <img
          src="mobbg.png"
          className="absolute block md:hidden -top-12 h-full w-full"
        />
        <img
          src="bubble.png"
          className="absolute h-[400px] rotate mb-[130px] md:mb-[80px]"
        />
        <img
          src="logo.png"
          className="absolute h-[180px] mb-[130px] md:mb-[80px] animate-pulse"
        />
      </div>

      <div className="relative flex justify-start items-center h-[500px] md:h-[700px] -translate-y-16">
        <img
          src="planet.webp"
          className="absolute top-8 -right-24 md:right-0 h-full opacity-70 md:opacity-100 -z-30"
        />
        <div className="w-full md:w-1/2 md:mt-0 mt-[600px] px-5 text-xl font-semibold">
          <span className="txtgradient">RIDEGEN</span> is an innovative
          decentralized network platform that leverages the transformative
          potential of smart contracts to redefine the dynamics of modern
          marketing. Built on a foundation of{" "}
          <span className="txtgradient">blockchain technology</span>,{" "}
          <span className="txtgradient">RIDEGEN</span> creates a secure and
          transparent ecosystem.
          <button
            onClick={useAppState.login}
            className="mt-3 bg-blue-500 w-32 flex justify-center items-center rounded-lg p-3 hover:bg-blue-600 text-sm cursor-pointer"
          >
            Register Now
          </button>
          <div className="mt-16 md:mt-0 flex flex-col md:flex-row justify-between w-full p-5 md:absolute -bottom-0 md:-bottom-14">
            <div className="flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50px"
                height="50px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 11q.825 0 1.413-.587T14 9t-.587-1.412T12 7t-1.412.588T10 9t.588 1.413T12 11M7 21v-2h4v-3.1q-1.225-.275-2.187-1.037T7.4 12.95q-1.875-.225-3.137-1.637T3 8V5h4V3h10v2h4v3q0 1.9-1.263 3.313T16.6 12.95q-.45 1.15-1.412 1.913T13 15.9V19h4v2zm0-10.2V7H5v1q0 .95.55 1.713T7 10.8m10 0q.9-.325 1.45-1.088T19 8V7h-2z"
                ></path>
              </svg>
              <p>Rewards</p>
              <p className="text-sm text-gray-500 mt-3 text-center">
                Our decentralized platform offers a variety of rewards for users
                who contribute to our ecosystem.
              </p>
            </div>
            <div className="mt-4 md:mt-0 ml-0 md:ml-4 flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50px"
                height="50px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19 13c.34 0 .67.04 1 .09V10a2 2 0 0 0-2-2h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6a2 2 0 0 0-2 2v10c0 1.11.89 2 2 2h7.81c-.51-.88-.81-1.9-.81-3c0-3.31 2.69-6 6-6M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9zm3 11a2 2 0 1 1 2-2c0 1.11-.89 2-2 2m10.5.25L17.75 22L15 19l1.16-1.16l1.59 1.59l3.59-3.59z"
                ></path>
              </svg>
              <p>Secure</p>
              <p className="text-sm text-gray-500 mt-3 text-center">
                Our platform is built on smart contracts, providing a high level
                of security for all users.
              </p>
            </div>
            <div className="mt-4 md:mt-0 ml-0 md:ml-4 mr-0 md:mr-5 flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45px"
                height="45px"
                viewBox="0 0 32 32"
              >
                <path
                  fill="currentColor"
                  d="M16 3.667C9.19 3.667 3.667 9.187 3.667 16S9.19 28.333 16 28.333c6.812 0 12.333-5.52 12.333-12.333S22.813 3.667 16 3.667m0 3c1.85 0 3.572.548 5.024 1.48L8.147 21.024A9.26 9.26 0 0 1 6.667 16c0-5.146 4.187-9.333 9.333-9.333m0 18.666a9.27 9.27 0 0 1-5.024-1.48l12.876-12.877A9.26 9.26 0 0 1 25.332 16c0 5.146-4.186 9.333-9.332 9.333"
                ></path>
              </svg>
              <p>No Admin</p>
              <p className="text-sm text-gray-500 mt-3 text-center">
                Smart contracts operate without the need for admin, as they are
                self-executing.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mb-8 items-center relative mt-[530px] md:mt-36 p-4 h-[670px] md:h-[400px]">
        <div className="bggradient2 shadow-xl md:w-3/4 w-full p-4 bg-[#242526] rounded-lg">
          <Carousel autoplay>
            <div className="w-full">
              <div className="flex flex-col items-center w-full pb-10 pt-4">
                <img src="earth.png" className="h-32" />
                <p className="text-gray-800 text-2xl font-bold">Blockchain</p>
                <p className="mt-3 px-3 font-semibold text-white">
                  Blockchain is a decentralized and distributed digital ledger
                  that records and verifies transactions across multiple
                  computers or nodes. It is a foundational technology that
                  underlies cryptocurrencies like Bitcoin, but its applications
                  go beyond digital currencies. In a traditional centralized
                  system, transactions and data are typically stored in a
                  single, trusted entity such as a bank or a government. In
                  contrast, a blockchain network consists of a network of
                  computers, known as nodes, that collectively maintain a shared
                  and synchronized ledger. Each transaction is grouped into a
                  "block" and added to a chain of previous blocks, forming a
                  chronological sequence of records.
                </p>
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col items-center w-full pb-10 pt-4">
                <img src="3dbnb.png" className="h-32" />
                <p className="text-gray-800 text-2xl font-bold">OPBNB</p>
                <p className="mt-3 px-3 font-semibold text-white">
                  opBNB is an BSC Layer 2 scaling solution built on the BNB
                  Chain, designed to enhance the speed, efficiency, and
                  cost-effectiveness of decentralized applications (dApps). By
                  leveraging Optimistic Rollups, opBNB bundles multiple
                  transactions together and processes them off-chain before
                  submitting them back to the main BSC chain. This reduces
                  congestion and lowers gas fees while maintaining security and
                  decentralization. opBNB is particularly aimed at developers
                  and users within the Binance ecosystem, providing a seamless
                  experience for DeFi, NFTs, and other blockchain-based
                  applications. It allows for faster transactions and greater
                  scalability, making it a key player in the growing ecosystem
                  of blockchain scalability solutions.
                </p>
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col items-center w-full pb-10 pt-4">
                <img src="hero.png" className="h-32" />
                <p className="text-gray-800 text-2xl font-bold">
                  Smart Contracts
                </p>
                <p className="mt-3 px-3 font-semibold text-white">
                  Smart contracts are self-executing contracts with the terms of
                  the agreement directly written into code. They are built on
                  blockchain platforms like Ethereum and are designed to
                  automate and facilitate the execution of agreements without
                  the need for intermediaries. Smart contracts work by enforcing
                  the predefined rules and conditions of an agreement, ensuring
                  that all parties involved fulfill their obligations. These
                  contracts are stored and replicated across multiple nodes on a
                  decentralized blockchain network, making them secure,
                  transparent, and resistant to tampering.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>

      {/* Footer */}

      <footer class="text-gray-600 blackbg mt-28 body-font">
        <div class="container px-5 py-12 mx-auto">
          <div class="flex flex-wrap md:text-left text-center order-first">
            <div class="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 class="title-font font-medium text-gray-400 tracking-widest text-sm mb-3">
                Links
              </h2>
              <nav class="list-none mb-10">
                <li className='mt-3'>
                  <a target='_blank' href='https://opbnb.bscscan.com/' class="text-yellow-300 cursor-pointer hover:text-gray-200 underline">OP BNB Explorer</a>
                </li>
                <li className='mt-3'>
                  <a target='_blank' href='https://coinmarketcap.com/currencies/bnb/' class="text-yellow-300 cursor-pointer hover:text-gray-200 underline">BNB</a>
                </li>
                <li className='mt-3'>
                  <a target='_blank' href='https://ridegen.vercel.app/logo.png' class="text-yellow-300 cursor-pointer hover:text-gray-200 underline">Ridegen Logo</a>
                </li>
                <li className='mt-3'>
                  <a target='_blank' class="text-yellow-300 cursor-pointer hover:text-gray-200 underline">Fourth Link</a>
                </li>
              </nav>
            </div>            
          </div>
        </div>
        <div class="bg-gray-100">
          <div class="container px-5 py-4 mx-auto flex items-center sm:flex-row flex-col">
            <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <img src='logo.png' className='h-12 mt-2' />
              <span class="ml-3 text-xl">RIDEGEN</span>
            </a>
            <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
              © 2024 RIDEGEN —
              <a
                href="https://twitter.com/knyttneve"
                rel="noopener noreferrer"
                class="text-gray-600 ml-1"
                target="_blank"
              >
                @All Copyright Reserved
              </a>
            </p>
            <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a class="text-gray-500">
                <svg
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a class="ml-3 text-gray-500">
                <svg
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a class="ml-3 text-gray-500">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a class="ml-3 text-gray-500">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Homepage