import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App'
import {ethers} from 'ethers'

const Balance = () => {
    const useAppState = useContext(AppState);

  return (
    <div className="mt-6 w-full flex justify-end items-center bg-[#8080821a] px-2 py-2 rounded-2xl shadow-lg red_blur">
        {/* <div className='flex'><span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1"><img src='usdt.png' className='h-6 mr-2' />  ${bal.usdt}</span></div> */}
        <div className='flex'><span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1"><img src='bnb.png' className='h-6 mr-2' />  {useAppState.bal.toFixed(5)}</span></div>
    </div>
  )
}

export default Balance