import React, {useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd';
import { AppstoreOutlined, SettingOutlined, CodeSandboxOutlined,DeploymentUnitOutlined , TeamOutlined } from '@ant-design/icons';

const Navigation = () => {
  const location = useLocation();

  return (
    <div className='flex justify-between items-end w-full whitespace-nowrap overflow-x-auto scrollbar-hide bg-gray-200 rounded-xl'>
        <Link to={'/dashboard'} className='md:w-1/4 min-w-[110px]'><div className={`${location.pathname == '/dashboard' ? "border-b-2 border-blue-500 text-blue-500" : "text-black"} w-full text-center p-3 h-full font-semibold text-sm  hover:border-b-2 border-blue-500 hover:text-blue-500`}><AppstoreOutlined/> Dashboard</div></Link>
        <Link to={'/team'} className='md:w-1/4 min-w-[110px] ml-4'><div className={`${location.pathname == '/team' ? "border-b-2 border-blue-500 text-blue-500" : "text-black"} w-full text-center p-3 h-full font-semibold text-sm  hover:border-b-2 border-blue-500 hover:text-blue-500`}><TeamOutlined /> Referrals</div></Link>  
        <Link to={'/matrix'} className='md:w-1/4 min-w-[110px] ml-4'><div className={`${location.pathname == '/matrix' ? "border-b-2 border-blue-500 text-blue-500" : "text-black"} w-full text-center p-3 h-full font-semibold text-sm  hover:border-b-2 border-blue-500 hover:text-blue-500`}><DeploymentUnitOutlined /> Community</div></Link>  
        <Link to={'/downline'} className='md:w-1/4 min-w-[110px] ml-4'><div className={`${location.pathname == '/downline' ? "border-b-2 border-blue-500 text-blue-500" : "text-black"} w-full text-center p-3 h-full font-semibold text-sm  hover:border-b-2 border-blue-500 hover:text-blue-500`}><CodeSandboxOutlined /> Team</div></Link>  
    </div>
  )
}

export default Navigation